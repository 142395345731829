<template>
  <div class="playbackPageWrap container">
    <header>
      <el-row class='hedaer-Bg'>
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class='logo-wrap'><img src='../../assets/image/homeImg/logo.png'/></div>
            <div class="app-title" @click="$router.push('/home')">在线培训平台</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class='main-content'>
      <el-row type="flex" class='contentWrap'>
        <el-col class="leftContent">
          <div class="grid-content bg-purple-light grid-content-left">
            <div class="leftSide" @click="$router.back(-1)">
              <div class='backBtn'>&lt;&nbsp;返回</div>
            </div>
          </div>
        </el-col>
        <el-col class="RightContent">
          <div class="grid-content bg-purple-light">
            <div class="chapterTitle">{{ chapterTitle }}</div>
            <div id="player"></div>
            <ejectQuestion :question="question" @submit="handleAfterEjectQuestionSubmit"></ejectQuestion>
          </div>
        </el-col>
      </el-row>

    </div>
    <footer></footer>
  </div>
</template>

<script>
import EjectQuestion from '@/views/playBack/ejectQuestion.vue'

export default {
  name: '',
  components: {EjectQuestion},
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      vodPlayerJs: 'https://g.alicdn.com/de/prismplayer/2.15.2/aliplayer-min.js',
      vid: '',
      playAuth: '',
      vodUrl: '',
      courseTitle: '',
      chapterTitle: '',
      studentChapterId: '',
      studentCourseId: '',
      timer: null,
      chapterList: [],
      currentChapterIndex: 0,
      currentChapter: null,
      isPlaying: false,
      // vid: ''
      currentDuration: 0,
      question: null,
      totalDuration: 0,
      skinLayout: [
        {
          'name': 'bigPlayButton',
          'align': 'cc',
          'x': 30,
          'y': 80
        },
        {
          'name': 'errorDisplay',
          'align': 'tlabs',
          'x': 0,
          'y': 0
        },
        {
          'name': 'infoDisplay'
        },
        {
          'name': 'controlBar',
          'align': 'blabs',
          'x': 0,
          'y': 0,
          'children': [
            {name: 'playButton', align: 'tl', x: 15, y: 12}, // 播放开始暂停按钮
            {name: 'timeDisplay', align: 'tl', x: 10, y: 7}, // 播放视频时间
            {name: 'fullScreenButton', align: 'tr', x: 10, y: 12}, // 全屏
            {name: 'volume', align: 'tr', x: 5, y: 10} // 音量
          ]
        }
      ],
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let {stuName} = userinfo
        this.userName = stuName
      }
    },
    loadPlayerScript (callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.vodPlayerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
      } else {
        callback()
      }
    },
    /**
     * 加载并初始化播放器
     */
    loadPlayer () {
      const player = this.createPlayer()
      /**
       * 当视频开始播放
       */
      player.on('play', () => {
        this.isPlaying = true
        if (this.currentDuration > 0 && this.currentDuration < this.totalDuration) {
          this.player.seek(this.currentDuration)
        }
      })
      /**
       * 暂停播放
       */
      player.on('pause', () => {
      })

      /**
       * 开始拖拽startSeek
       */
      player.on('startSeek', (e) => {
        this.onPlayerStartSeek()
      })
      /**
       * 完成拖拽completeSeek
       */
      player.on('completeSeek', (e) => {
        this.onPlayerEndSeek()
      })
      /**
       * 播放中进度回调
       */
      player.on('timeupdate', (e) => {
        this.onPlayerTimeUpdate()
      })
      /**
       * 播放结束回调
       */
      player.on('ended', () => {
      })
    },
    /**
     *创建播放器实例
     * @returns {Window.Aliplayer}
     */
    createPlayer () {
      if (this.player) {
        this.player.dispose()
      }
      const Aliplayer = window.Aliplayer
      const saveTime = function (memoryVideo, currentTime) {
        localStorage.setItem(memoryVideo, currentTime)
      }

      const getTime = (memoryVideo) => {
        console.log(this.currentDuration)
        // return返回的是自定义起播时间
        if (this.currentDuration > 0 && this.currentDuration < this.totalDuration) {
          return this.currentDuration
        } else {
          return 0
        }
      }
      this.player = new Aliplayer({
        id: 'player',
        width: 1045,
        height: 620,
        vid: this.vid,
        playauth: this.playAuth,
        autoplay: true,
        showLine: 'false',
        preload: false,
        isLive: false,
        disableSeek: true,
        skinLayout: this.skinLayout,
        components: [{
          name: 'MemoryPlayComponent',
          type: window.AliPlayerComponent.MemoryPlayComponent,
          /* Set the first parameter to true to enable auto play. The default is false. */
          args: [true, getTime(), saveTime]
        }]
      }, (player) => {
        this.isPlaying = true
        if (this.currentDuration > 0 && this.currentDuration < this.totalDuration) {
          player.seek(this.currentDuration)
        }
      })
      if (this.currentDuration > 0 && this.currentDuration < this.totalDuration) {
        this.player.seek(this.currentDuration)
      }
      return this.player
    },
    /**
     * 拖动进度条开始
     */
    onPlayerStartSeek () {
      // 拖拽开始，暂停视频
      this.player.pause()
    },
    /**
     * 拖动进度条结束
     */
    onPlayerEndSeek () {
      // 拖拽结束，播放视频
      this.player.play()
    },
    /**
     * 播放器进度变化
     */
    onPlayerTimeUpdate () {
      if (!this.isPlaying) {
        return
      }
      const currentTime = this.player.getCurrentTime()
      // console.log(currentTime - 30, this.currentDuration, this.totalDuration)
      // 播放时间超过30秒或者播放完成
      if (currentTime - 30 > this.currentDuration || currentTime >= this.totalDuration) {
        this.addStudyTrack(currentTime)
        this.currentDuration = currentTime
      }
      // 播放完成，跳转下一个视频
      if (currentTime >= this.totalDuration) {
        // 从播放时间和总时间判断是不是播放结束了
        this.isPlaying = false
        this.gotoNextChapter()// 结束后走这个事件
      }
      // 答题
      if (this.currentChapter.courseEjectQuestionVOList && this.currentChapter.courseEjectQuestionVOList.length > 0) {
        this.currentChapter.courseEjectQuestionVOList.forEach(item => {
          if (item.already != 'Y') {
            // 唤起答题框，暂停视频播放
            if (Number(item.ejectDuration) === Number(currentTime.toFixed(0))) {
              this.remindTitle = ''
              this.player.pause()
              if (this.player.fullscreenService.getIsFullScreen) {
                this.player.fullscreenService.cancelFullScreen()
              }
              this.question = item
              console.log('=========this.eduAnswerData============================', this.eduAnswerData)
              item.already = 'Y' // 本次播放周期，同一题目只用答一次
            }
          }
        })
      }
    },
    /**
     * 跳转到下一章节
     */
    gotoNextChapter () {
      if (this.currentChapterIndex < this.chapterList.length) {
        console.log(this.currentChapterIndex)
        const currentChapterIndex = this.currentChapterIndex + 1
        this.currentChapterIndex = currentChapterIndex
        // 用当前的this.chapterList的index,+1得到下一个视频在this.chapterList的位置
        const currentChapter = this.chapterList[currentChapterIndex]// 获取到下个视频数据
        if (currentChapter != null) {
          this.initCurrentChapter(currentChapter)// 开始给播放器换属性
        }
      } else {
        // todo 弹出提示学习完成
      }
    },
    /**
     * 上报学习进度
     * @param currentTime 从播放器取到的当前播放的时间点
     */
    addStudyTrack (currentTime) {
      const params = {
        studentChapterId: this.studentChapterId,
        studentCourseId: this.studentCourseId,
        studyDuration: currentTime
      }
      this.$axios.post(this.$apiUrl.addStudyTrack, params).then(res => {
        if (res && res.data.code == 200) {
          if (res.data.data.courseStudyDuration >= res.data.data.courseDuration) {
            let examInfoId = res.data.data.examInfoId
            this.gotoExam(examInfoId)
          }
        }
      })
    },
    /**
     * 学习完成跳转考试
     * @param examInfoId 考试ID
     */
    gotoExam (examInfoId) {
      this.$message({
        message: '您已学完所有课程！',
        type: 'success'
      })
      // this.$confirm('您已通过学习，是否开始考试？', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   beforeClose: (action, instance, done) => {
      //     if (action === 'confirm') {
      //       setTimeout(() => {
      //         done()
      //         setTimeout(() => {
      //           this.$router.push(`/examItemInfo?examInfoId=${examInfoId}&code=start`)
      //         }, 500)
      //       }, 600)
      //     } else {
      //       done()
      //     }
      //   }
      // })
    },
    /**
     * 获取当前学习的章节
     * @param studentChapterId 学生报名学习的对应章节ID
     * @returns {null|*} 学员报名的章节信息，获取不到，返回null
     */
    getCurrentChapter (studentChapterId) {
      const length = this.chapterList && this.chapterList.length
      for (let i = 0; i < length; i++) {
        if (this.chapterList[i].id === studentChapterId) {
          this.currentChapterIndex = i
          return this.chapterList[i]
        }
      }
      return null
    },
    /**
     * 加载课程详情
     */
    getCourseDetail () {
      const studentChapterId = this.$route.query.studentChapterId
      const courseId = this.$route.query.courseId
      this.courseId = courseId
      this.studentChapterId = studentChapterId

      this.$axios.get(this.$apiUrl.loadCourseDetail, {params: {courseId: courseId}}).then(res => {
        const resData = res.data.data
        const {chapterList, courseTitle, id} = resData
        this.courseTitle = courseTitle
        this.studentCourseId = id
        this.chapterList = chapterList
        const currentChapter = this.getCurrentChapter(studentChapterId)
        if (currentChapter != null) {
          this.initCurrentChapter(currentChapter)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    /**
     * 初始化播放章节
     * @param studentChapter
     */
    initCurrentChapter (studentChapter) {
      // 最后一步替换那个player所有属性播放下个视频
      this.currentChapter = studentChapter
      this.studentChapterId = studentChapter.id
      this.vid = studentChapter.vodId
      this.chapterTitle = studentChapter.chapterTitle
      this.vodUrl = studentChapter.vodUrl
      this.currentDuration = studentChapter.studyDuration
      this.totalDuration = studentChapter.totalDuration
      // 获取播放器授权,并加载播放器
      this.getVideoPlayAuthFun()
    },
    /**
     * 获取视频的播放授权
     */
    getVideoPlayAuthFun () {
      this.$axios.post(this.$apiUrl.getVideoPlayAuth, {videoId: this.vid}).then(res => {
        console.log(res)
        if (res.data.success) {
          this.playAuth = res.data.data.playAuth
          this.loadPlayerScript(this.loadPlayer)
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleAfterEjectQuestionSubmit () {
      this.question.already = 'Y'
      this.question = null
      this.player.play()
    }

  },
  mounted () {
    if (this.$route.query.pageFrom) {
    } else {
      this.skinLayout.forEach(item => {
        if (item.name == 'controlBar') {
          item.children.push({name: 'progress', align: 'blabs', x: 0, y: 44}) // 播放进度条
          item.children.push({name: 'setting', align: 'tr', x: 15, y: 12}) // 播放倍速、清晰度设置
          // 只取消倍速的播放
          // document.getElementsByClassName('prism-setting-speed')[0].style.display='none'
        }
      })
    }
    this.getCourseDetail()
    this.getUserInfo()
  },
  destroyed () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style scoped lang="scss">
.playbackPageWrap {
  position: relative;
  min-height: 100vh;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 42px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 42px;
    padding: 0 100px;

    .header-left-content {
      font-size: 20px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 33px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 30px;
        line-height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      ::v-deep .el-dropdown {
        font-size: 14px;
        color: #4774df;
        background-color: #fff;
        height: 30px;
        line-height: 30px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .main-content {
    width: 1240px;
    margin: 0 auto 0;
    padding: 0px;
    min-height: 700px;
    padding-bottom: 50px;

    .contentWrap {
      justify-content: center
    }

    .leftContent {
      width: 94px;
    }

    .grid-content-left {
      display: flex;

      .leftSide {
        width: 74px;
        height: 699px;
        background: #4C5664;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        color: #ffff;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;

        .backBtn {
          width: 100%;
          background-color: #2F3A4A;
          height: 50px;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
      }

      .navAndSign {
        height: 699px;
        width: 149px;
        background: #ffffff;

        .returnIcon {
          padding: 24px 0px 30px 22px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          cursor: pointer;
        }

        .returnIcon:hover {
          color: #a4c2fa;
        }

        .title {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border-bottom: 1px solid rgba(112, 112, 112, 0.2);
          padding: 9px 18px;
        }
      }

      .signWrap {
        width: 249px;
        height: 593px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div {
          padding: 30px 0px;
          color: "#000000";
          display: flex;
          flex-direction: column;
          font-size: 18px;
          cursor: pointer;
          text-align: center;

          > img {
            width: 50px;
            height: 50px;
          }
        }

        .nosignText {
          color: #1c4ecc;
        }

        .nosignoutText {
          color: #ff6f00;
        }
      }
    }

    .RightContent {
      width: 1045px;
      padding: 30px 0px;

      .chapterTitle {
        padding-left: 19px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 14px;
      }
    }

    .courseCover {
      width: 1045px;
      height: 620px;
    }

    #player {
      width: 1045px;
      height: 620px;
    }
  }

  footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    background: url('../../assets/image/homeImg/headerBg.png') no-repeat;
    background-size: 100% 100%;
  }
}
</style>
